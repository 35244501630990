import Head from 'next/head';

export default function Home() {
  return (
    <div>
      <Head>
        <title>404 - Heepow</title>
        <meta name='description' content='Generated by create next app' />
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <div>
        <h1>404 - Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    </div>
  );
}
